import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    currency: { type: String, default: 'USD' }
  }

  static targets = ["itemTax", "itemQuantity", "itemUnitAmount", "itemAmount",
                    "amountDiscountBeforeTax", "percentageDiscountBeforeTax",
                    "amountDiscountAfterTax", "percentageDiscountAfterTax",
                    "amountFee", "percentageFee",
                    "subtotalAmount", "totalAmount", "depositAmount",
                    "globalTax", "globalTaxAmount",
                    "employeesList", "employeesEditor"]

  initialize() {
    this.currencyFormatter = Intl.NumberFormat('en', {style: 'currency', currency: this.currencyValue})
  }

  connect() {
    this.calculateTotal()
  }

  calculateTotal() {
    if(!this.hasSubtotalAmountTarget) return;

    let total = 0
    let subtotal = 0
    let amountDiscountBeforeTax = 0
    let percentageDiscountBeforeTax = 0
    let amountDiscountAfterTax = 0
    let percentageDiscountAfterTax = 0
    let amountFee = 0
    let percentageFee = 0
    let itemsTaxAmount = 0
    let globalTax = 0

    this.itemQuantityTargets.forEach((el, index) => {
      let quantity = this.__inputValueToFloat(el.value)
      let amount = this.__inputValueToFloat(this.itemUnitAmountTargets[index].value)

      let itemAmount = quantity * amount

      this.itemAmountTargets[index].innerHTML = this.currencyFormatter.format(itemAmount)

      subtotal += itemAmount

      if(this.itemTaxTargets[index] && this.itemTaxTargets[index].value) {
        itemsTaxAmount += itemAmount * this.__inputValueToFloat(this.itemTaxTargets[index].value) / 100
      }
    })

    this.globalTaxTargets.forEach((el, index) => { globalTax += this.__inputValueToFloat(el.value) })
    this.amountDiscountBeforeTaxTargets.forEach((el, index) => { amountDiscountBeforeTax += this.__inputValueToFloat(el.value) })
    this.percentageDiscountBeforeTaxTargets.forEach((el, index) => { percentageDiscountBeforeTax += this.__inputValueToFloat(el.value) })
    this.amountDiscountAfterTaxTargets.forEach((el, index) => { amountDiscountAfterTax += this.__inputValueToFloat(el.value) })
    this.percentageDiscountAfterTaxTargets.forEach((el, index) => { percentageDiscountAfterTax += this.__inputValueToFloat(el.value) })

    this.amountFeeTargets.forEach((el, index) => { amountFee += this.__inputValueToFloat(el.value) })
    this.percentageFeeTargets.forEach((el, index) => { percentageFee += this.__inputValueToFloat(el.value) })

    let amountBeforeTax = subtotal - amountDiscountBeforeTax
    amountBeforeTax -= amountBeforeTax * percentageDiscountBeforeTax / 100

    let globalTaxAmount = 0
    if(this.hasGlobalTaxTarget) {
      globalTaxAmount = amountBeforeTax * globalTax / 100
      this.globalTaxAmountTarget.innerHTML = this.currencyFormatter.format(globalTaxAmount)
    }

    total = amountBeforeTax + globalTaxAmount + itemsTaxAmount

    total -= amountDiscountAfterTax
    total -= total * percentageDiscountAfterTax / 100

    if( total < 0 ) total = 0;

    total += (total * percentageFee / 100) + amountFee

    this.subtotalAmountTarget.innerHTML = this.currencyFormatter.format(subtotal)
    this.totalAmountTarget.innerHTML = this.currencyFormatter.format(total)

    if(this.hasDepositAmountTarget) {
      let depositAmount = this.__inputValueToFloat(this.depositAmountTarget.value)
      let depositContainer = $(this.depositAmountTarget).parent()

      if( depositAmount > total ) {
        depositContainer.addClass("form-control is-invalid remote-modal")

        if(!Notify.isVisible()) {
          Notify.error("Deposit amont must be less than total. Please fix this issue before continue.")
        }
      } else {
        depositContainer.removeClass("form-control is-invalid remote-modal")
      }
    }
  }

  toggleEmployeesEditorOn(event) {
    event.preventDefault()

    $(this.employeesListTarget).toggle()
    $(this.employeesEditorTarget).toggle()
  }

  removeItem(event) {
    event.preventDefault()

    let fieldsContainer = event.target.closest(".nested-record")
    let itemsContainer = $(fieldsContainer).parent();
    // if item persisted marked it to delete, hide and then submit form (force autosave)
    // otherwise just remove item and recalculate totals
    if(fieldsContainer.dataset.persisted == "true") {
      fieldsContainer.querySelector("input[name*='_destroy']").value = 1
      fieldsContainer.style.display = 'none'

      Rails.fire(event.target.closest("form"), 'submit')
    } else {
      fieldsContainer.remove()
      this.calculateTotal()
    }

    // show 'No items added' message
    if(itemsContainer.find('.nested-record').length == 0) {
      itemsContainer.find('.no-items-added-line').removeClass('d-none')
    }
  }

  __inputValueToFloat(value) {
    return parseFloat(value.replace(/,/g, ''))
  }
}
